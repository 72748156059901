import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminData, deactivateUser } from "../features/admin/adminSlice";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { FaUserSlash, FaInfoCircle, FaClipboard } from "react-icons/fa";
import PopupComponent from "../components/PopupComponent";
import { getCheckLists } from "../features/checklist/checkListSlice";
import { useNavigate } from "react-router-dom";
import CheckLists from "./CheckLists";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading, deactivatedUserId } = useSelector(
    (state) => state.admin
  );
  const [sortedUsers, setSortedUsers] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    dispatch(fetchAdminData())
      .unwrap()
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
  }, [dispatch]);

  useEffect(() => {
    if (data?.users) {
      setSortedUsers([...data.users]);
    }
  }, [data]);

  const sortUsers = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedArray = [...sortedUsers].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setSortedUsers(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "↑" : "↓";
    }
    return "↕";
  };

  const handleDeactivateUserClick = (userId) => {
    setSelectedUserId(userId);
    setIsPopupOpen(true);
  };

  const confirmDeactivateUser = () => {
    dispatch(deactivateUser(selectedUserId))
      .unwrap()
      .then(() => {
        toast.success("User deactivated successfully", { autoClose: 2000 });
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
    setIsPopupOpen(false);
  };

  const cancelDeactivateUser = () => {
    setIsPopupOpen(false);
    setSelectedUserId(null);
  };

  const handleInfoClick = (userId) => {
    navigate(`/checklists/${userId}`);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={`min-h-screen p-6 ${isDisabled ? "disabled" : ""}`}>
      <div className="flex flex-col max-w-7xl mx-auto bg-white p-6">
        <h1 className="text-2xl font-semibold text-gray-700 mb-6">
          Admin Dashboard
        </h1>

        {/* Dashboard Cards */}
        <div className="grid md:grid-cols-3 gap-4 mb-8">
          {/* Total Users Card */}
          <div className="flex flex-col items-center justify-center bg-gray-50 p-4 rounded-lg shadow">
            <h2 className="text-lg font-thin text-gray-600">Total Users</h2>
            <p className="text-2xl font-bold text-gray-700">
              {data?.totalUsers}
            </p>
          </div>

          {/* Total Checklists Card */}
          <div className="flex flex-col items-center justify-center bg-gray-50 p-4 rounded-lg shadow">
            <h2 className="text-lg font-thin text-gray-600">
              Total Checklists
            </h2>
            <p className="text-2xl font-bold text-gray-700">
              {data?.totalChecklists}
            </p>
          </div>

          {/* Total Items Card */}
          <div className="flex flex-col items-center justify-center bg-gray-50 p-4 rounded-lg shadow">
            <h2 className="text-lg font-thin text-gray-600">Total Items</h2>
            <p className="text-2xl font-bold text-gray-700">
              {data?.totalItems}
            </p>
          </div>
        </div>

        {/* User Table */}
        <div>
          <h2 className="text-xl font-medium text-gray-600 mb-4">User List</h2>
          <table className="table-auto w-full bg-gray-50 rounded-lg">
            <thead>
              <tr className="bg-gray-50 text-gray-600 font-light">
                <th className="px-4 py-2 font-normal">Name</th>
                <th className="px-4 py-2 font-normal">Email</th>
                <th
                  className="px-4 py-2 font-normal cursor-pointer"
                  onClick={() => sortUsers("checklistCount")}
                >
                  Checklists {getSortIcon("checklistCount")}
                </th>
                <th
                  className="px-4 py-2 font-normal cursor-pointer"
                  onClick={() => sortUsers("itemCount")}
                >
                  Items {getSortIcon("itemCount")}
                </th>
                <th className="px-4 py-2 font-normal">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map((user, index) => (
                <tr
                  key={user._id}
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-white"
                  }  border-x-0 border-b`}
                >
                  <td
                    className={`px-2 py-4 my-4 font-thin text-center ${
                      user.isActive === false
                        ? " text-gray-400"
                        : "text-gray-600"
                    }  `}
                  >
                    {`${user.name} (${
                      user.roles.length === 0 ? "user" : user.roles
                    })`}
                  </td>
                  <td
                    className={`px-2 py-4 my-4 font-thin text-center ${
                      user.isActive === false
                        ? " text-gray-400"
                        : "text-gray-600"
                    }  `}
                  >
                    {user.email}
                  </td>
                  <td
                    className={`px-2 py-4 my-4 font-thin text-center ${
                      user.isActive === false
                        ? " text-gray-400"
                        : "text-gray-600"
                    }  `}
                  >
                    {user.checklistCount}
                  </td>
                  <td
                    className={`px-2 py-4 my-4 font-thin text-center ${
                      user.isActive === false
                        ? " text-gray-400"
                        : "text-gray-600"
                    }  `}
                  >
                    {user.itemCount}
                  </td>
                  <td
                    className={`px-2 py-4 my-4 font-thin text-center ${
                      user.isActive === false
                        ? " text-gray-400"
                        : "text-gray-600"
                    }  `}
                  >
                    <button
                      onClick={() => handleDeactivateUserClick(user._id)}
                      className={`px-4 py-2 my-2 font-thin text-red-500 hover:text-red-700 ${
                        user.isActive === false
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                      disabled={user.isActive === false}
                    >
                      <FaUserSlash />
                    </button>
                    <button
                      className="px-4 py-2 my-2 font-thin"
                      onClick={() => handleInfoClick(user._id)}
                    >
                      <FaClipboard />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <PopupComponent isOpen={isPopupOpen} onClose={cancelDeactivateUser}>
            <h2 className="flex items-center text-gray-600 justify-center text-xl font-bold">
              Confirm Deactivation
            </h2>
            <p className="text-gray-400">
              Are you sure you want to deactivate this user?
            </p>
            <div className="flex items-center justify-center">
              <button
                onClick={confirmDeactivateUser}
                className="bg-red text-white px-4 py-2 rounded m-2"
              >
                Yes, Deactivate
              </button>
              <button
                onClick={cancelDeactivateUser}
                className="bg-gray-500 text-white px-4 py-2 rounded m-2"
              >
                Cancel
              </button>
            </div>
          </PopupComponent>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
