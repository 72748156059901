import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

// Fetch dashboard data
const fetchDashboardData = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + '/user/dashboard', config)
  return response.data
}

// Fetch activities
const fetchActivities = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + '/user/activities', config)
  return response.data
}

const dashboardService = {
  fetchDashboardData,
  fetchActivities,
}

export default dashboardService
