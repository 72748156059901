import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuditLogs } from "../features/admin/adminSlice";
import Spinner from "../components/Spinner";

const AuditLogs = () => {
  const dispatch = useDispatch();
  const { auditLogs, isLoading } = useSelector((state) => state.admin);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchAuditLogs());
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen p-6">
      <div className="flex flex-col max-w-7xl mx-auto bg-white p-6">
        <h1 className="text-2xl font-semibold text-gray-700 mb-6">
          Audit Logs
        </h1>
        <table className="table-auto w-full bg-gray-50 rounded-lg text-center">
          <thead>
            <tr className="bg-gray-50 text-gray-600 font-light">
              <th className="px-4 py-2 font-normal">User</th>
              <th className="px-4 py-2 font-normal">Action</th>
              <th className="px-4 py-2 font-normal">Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {auditLogs &&
              auditLogs.map((log) => (
                <tr key={log._id} className="bg-white border-x-0 border-b">
                  <td className="px-2 py-4 my-4 font-thin text-center text-gray-600">
                    {log.user.name} ({log.user.email})
                  </td>
                  <td className="px-2 py-4 my-4 font-thin text-center text-gray-600">
                    {log.action}
                  </td>
                  <td className="px-2 py-4 my-4 font-thin text-center text-gray-600">
                    {new Date(log.timestamp).toLocaleString()}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AuditLogs;
