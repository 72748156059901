import React, { useEffect, useState } from "react";
import CheckListItem from "../components/CheckListItem";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";

import {
  createANewCheckList,
  addNewChecklistLocally,
  deleteACheckList,
} from "../features/checklist/checkListSlice";
import {
  deleteACheckListItem,
  addNewChecklistItemLocally,
} from "../features/checklistItem/checkListItemSlice";
import { toast } from "react-toastify";
import CheckListTitle from "../components/CheckListTitle";

const textStyles = {
  textAlign: "center",
  fontSize: "24px",
  textStyles: "bold",
};

function CheckList({ checklist, enableAddButton }) {
  const dispatch = useDispatch();

  let checkListItems = useSelector((state) =>
    state.checkListItems.checkListItems.filter(
      (item) => item.checkListID === checklist._id
    )
  );
  let filteredCheckListItems = useSelector((state) =>
    state.checkListItems.filteredItems.filter(
      (item) => item.checkListID === checklist._id
    )
  );
  const isFilterApplied = useSelector(
    (state) => state.checkListItems.isFilterApplied
  );
  let items = checkListItems;

  // if (isFilterApplied === false) {
  //   items = checkListItems;
  // } else {
  //   items = filteredCheckListItems;
  // }
  //const sortedData = items.sort((a, b) => (a.status === "pending" ? -1 : 1));
  const sortedData = items.sort((a, b) => {
    // Define priority groups for statuses
    const statusPriority = {
      pending: 1,
      "in progress": 1, // Treat "in-progress" with the same priority as "pending"
      completed: 2,
    };

    // Compare statuses based on priority
    if (statusPriority[a.status] !== statusPriority[b.status]) {
      return statusPriority[a.status] - statusPriority[b.status];
    }

    // If statuses are the same, compare times (as JavaScript Date objects)
    const timeA = new Date(a.updatedAt); // Parse the time strings into Date objects
    const timeB = new Date(b.updatedAt);
    return timeB - timeA; // Subtrac
  });
  items = sortedData;
  // Sorting the array by the 'status' field

  let [textBoxes, setTextBoxes] = useState(filteredCheckListItems);

  const handleAddCheckListItem = () => {
    // const newCheckListItem = [...textBoxes, { description: '', status: "pending" }]
    //setTextBoxes(newCheckListItem);

    dispatch(
      addNewChecklistItemLocally({
        description: "",
        status: "pending",
        checkListID: checklist._id,
        isEditing: true,
      })
    );
  };

  const handleCheckListDelete = () => {
    // const updatedTextBoxes = [...textBoxes];
    //updatedTextBoxes[index] = { ...textBoxes[index], description, status: updatedTextBoxes[index].status };

    // console.log(updatedTextBoxes[index])

    // e.preventDefault()
    dispatch(deleteACheckList({ checkListID: checklist._id }))
      .unwrap()
      .then(() => {
        // We got a good response so navigate the user
        //   navigate('/checklist')
        //  updatedTextBoxes.splice(index, 1)
        //   setTextBoxes(updatedTextBoxes);
        toast.success("deleted");
      })
      .catch(toast.error);

    //  console.log("delete :" + index)
  };

  const handleCheckListTitleTextBoxSave = async (name) => {
    const newChecklist = {
      name,
      status: "pending",
      items: [],
      checkListID: checklist._id,
    };

    dispatch(createANewCheckList(newChecklist))
      .unwrap()
      .then(() => {
        // We got a good response so navigate the user
        //   navigate('/checklist')
        checklist = null;
        toast.success("saved");
      })
      .catch(toast.error);
  };

  const handleCheckListTitleCheckboxChange = () => {
    // const updatedTextBoxes = [...textBoxes];
    // updatedTextBoxes[index] = { ...textBoxes[index], description: updatedTextBoxes[index].description, status: updatedTextBoxes[index].status === "pending" ? "completed" : "pending" };
    // setTextBoxes(updatedTextBoxes);
  };

  const handleAddCheckListBox = () => {
    dispatch(createANewCheckList({ name: "test", status: "pending" }))
      .unwrap()
      .then(() => {
        // We got a good response so navigate the user
        //   navigate('/checklist')
        toast.success("created");
      })
      .catch(toast.error);
  };

  // return(
  //     <div>
  //         {"checklistID:"+checklist._id+"items:::"+JSON.stringify(filteredCheckListItems)}
  //     </div>
  // )

  return (
    <div>
      <div className="relative min-h-[100vh]  flex flex-col justify-center mt-10 mx-10 card shadow border border-gray-100 break-words">
        <CheckListTitle checklist={checklist} />

        <div className={`mt-10 ${enableAddButton ? "text-box-container" : ""}`}>
          {" "}
          {/* Container for all the CheckListItem components */}
          {items.map((checkListItem, index) => (
            <CheckListItem key={checkListItem._id} item={checkListItem} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CheckList;
