import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/checklist'

const createANewCheckList = async (checkListData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, checkListData, config)

  return response.data
}


// delete a checklistitem
const deleteACheckList = async (checkListData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + "/" + checkListData.checkListID, config)

  return response.data
}

// Get checklists
const getCheckLists = async (token,userId) => {
  if(userId === undefined){
    userId = ''
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL+'?userId='+userId, config)

  return response.data
}

const getSearchChecklistAndItem = async (token,searchText) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(`${API_URL}/action/search?query=${searchText}`, config)

  return response.data
}

const getUserInfo = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/user/${userId}`, config);

  return response.data;
};

const checkListService = {
  getCheckLists,
  createANewCheckList,
  deleteACheckList,
  getSearchChecklistAndItem,
  getUserInfo
}

export default checkListService
