import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dashboardService from './dashboardService'
import { extractErrorMessage } from '../../utils'

const initialState = {
  inProgressItems: null,
  deadlineItems: [],
  itemsToStartTomorrow: [],
  overdueItems: [],
  totalInProgress: 0,
  totalDeadline: 0,
  totalToStartTomorrow: 0,
  totalOverdue: 0,
  completedItems: [],
  totalCompleted: 0,
  dueItems: [],
  activities: [], // Add activities to the initial state
  isLoading: false,
}

// Fetch dashboard data
export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchDashboardData',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await dashboardService.fetchDashboardData(token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.inProgressItems = action.payload.inProgressItems || []
        state.deadlineItems = action.payload.deadlineItems || []
        state.itemsToStartTomorrow = action.payload.itemsToStartTomorrow || []
        state.overdueItems = action.payload.overdueItems || []
        state.totalInProgress = action.payload.totalInProgress || 0
        state.totalDeadline = action.payload.totalDeadline || 0
        state.totalToStartTomorrow = action.payload.totalToStartTomorrow || 0
        state.totalOverdue = action.payload.totalOverdue || 0
        state.completedItems = action.payload.completedItems || []
        state.totalCompleted = action.payload.totalCompleted || 0
        state.dueItems = action.payload.dueItems || []
        state.activities = action.payload.activities || [] // Add activities to the state
        state.isLoading = false
      })
      .addCase(fetchDashboardData.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default dashboardSlice.reducer
