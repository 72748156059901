import React, { useState, useEffect, useRef } from "react";
import {
  AiOutlineEdit,
  AiFillCheckCircle,
  AiFillDelete,
  AiFillCloseCircle,
  AiOutlinePlus,
  AiFillTag,
  AiTwotoneTag,
  AiTwotoneTags,
  AiOutlineTag,
  AiFillClockCircle,
  AiOutlineClockCircle,
  AiOutlinePauseCircle,
  AiOutlineStop,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiFillHourglass,
  AiOutlineHourglass,
} from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  createOrUpdateACheckListItem,
  deleteACheckListItem,
  filterItemsByTags,
} from "../features/checklistItem/checkListItemSlice";
import { toast } from "react-toastify";
import PopupComponent from "../components/PopupComponent";
import Tag from "../components/Tag";
import TagPopup from "../components/TagPopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const statusOptions = [
  {
    value: "pending",
    label: "Pending",
    icon: <AiOutlineClockCircle className="text-yellow-500" />,
  },
  {
    value: "in progress",
    label: "In Progress",
    icon: <AiOutlineHourglass className="text-blue-500" />,
  },
  {
    value: "completed",
    label: "Completed",
    icon: <AiOutlineCheckCircle className="text-green-500" />,
  },
  {
    value: "on hold",
    label: "On Hold",
    icon: <AiOutlineStop className="text-orange-500" />,
  },
  {
    value: "blocked",
    label: "Blocked",
    icon: <AiOutlineExclamationCircle className="text-red-500" />,
  },
];

function CheckListItem({ item }) {
  const [Item, setItem] = useState(item);
  const [isEditing, setIsEditing] = useState(Item.isEditing);
  const [checked, setChecked] = useState(Item.status === "completed");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isRangeMode, setIsRangeMode] = useState(false);
  const [targetDateRange, setTargetDateRange] = useState({
    startDate: item.startDate || null,
    endDate: item.endDate || null,
  });
  const [targetDate, setTargetDate] = useState(item.startDate || null);
  const [status, setStatus] = useState(Item.status);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const statusDropdownRef = useRef(null);
  const calendarRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setItem(item);
    setTargetDateRange({
      startDate: item.startDate ? new Date(item.startDate) : null,
      endDate: item.endDate ? new Date(item.endDate) : null,
    });
    setTargetDate(item.startDate ? new Date(item.startDate) : null);
    setStatus(item.status);
  }, [item]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        statusDropdownRef.current &&
        !statusDropdownRef.current.contains(event.target)
      ) {
        setIsStatusDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCloseClick = () => {
    if (!Item._id) {
      setItem({ ...Item, description: "" });
    }
    setIsEditing(false);
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setIsEditing(true);
    setIsStatusDropdownOpen(false);
  };

  const handleSaveClick = () => {
    if (Item.description && Item.description.length > 0) {
      dispatch(
        createOrUpdateACheckListItem({
          checkListID: Item.checkListID,
          description: Item.description,
          status: status,
          itemID: Item._id,
          tags: Item.tags,
          startDate: targetDateRange.startDate,
          endDate: targetDateRange.endDate,
        })
      )
        .unwrap()
        .then(() => {
          toast.success("Saved");
        })
        .catch((error) => {
          toast.error(error.message || "Something went wrong!", {
            autoClose: 2000,
          });
        });
      setIsEditing(false);
    }
  };

  const handleDeleteClick = () => {
    setIsPopupOpen(true);
  };

  const confirmDelete = () => {
    if (Item._id) {
      dispatch(
        deleteACheckListItem({
          checkListID: Item.checkListID,
          itemID: Item._id,
        })
      )
        .unwrap()
        .then(() => {
          toast.success("Deleted");
        })
        .catch(toast.error);
      setIsEditing(false);
    }
    setIsPopupOpen(false);
  };

  const cancelDelete = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (event) => {
    setItem({ ...Item, description: event.target.value });
  };

  const handleTagPopupOpen = () => {
    setIsTagPopupOpen(true);
  };

  const handleTagPopupClose = () => {
    setIsTagPopupOpen(false);
  };

  const handleTagDelete = (tag) => {
    const updatedTags = Item.tags.filter((t) => t !== tag);
    setItem({ ...Item, tags: updatedTags });
    dispatch(
      createOrUpdateACheckListItem({
        checkListID: Item.checkListID,
        description: Item.description,
        status: Item.status,
        itemID: Item._id,
        tags: updatedTags,
        startDate: targetDateRange.startDate,
        endDate: targetDateRange.endDate,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Tag deleted");
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
  };

  const handleTagClick = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  useEffect(() => {
    if (selectedTags.length > 0) {
      dispatch(filterItemsByTags(selectedTags));
    }
  }, [selectedTags, dispatch]);

  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a href={part} target="_blank" key={index} className="break-all">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const handleCalendarClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleDateChange = (dates) => {
    if (isRangeMode) {
      const [start, end] = dates;
      setTargetDateRange({
        startDate: start, //? new Date(start) : null,
        endDate: end, // ? new Date(end) : null,
      });
    } else {
      setTargetDate(dates);
    }
  };

  const handleDateConfirm = () => {
    dispatch(
      createOrUpdateACheckListItem({
        checkListID: Item.checkListID,
        description: Item.description,
        status: Item.status,
        itemID: Item._id,
        tags: Item.tags,
        startDate: isRangeMode
          ? targetDateRange.startDate
            ? new Date(targetDateRange.startDate)
            : null
          : targetDate
          ? new Date(targetDate)
          : null,
        endDate: isRangeMode
          ? targetDateRange.endDate
            ? new Date(targetDateRange.endDate)
            : null
          : null //targetDate
          ? null //new Date(targetDate)
          : null,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Date updated");
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
    setIsCalendarOpen(false);
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const calculateTotalHours = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
    const diffInMs = new Date(endDate) - new Date(startDate);
    return Math.round(diffInMs / (1000 * 60 * 60));
  };

  const calculateHoursFromNow = (date) => {
    if (!date) return 0;
    const now = new Date();
    const diffInMs = new Date(date) - now;
    if (diffInMs < 0) return 0; // Return 0 if the date has gone past the current date
    return Math.round(diffInMs / (1000 * 60 * 60));
  };

  const selectedStatusIcon = statusOptions.find(
    (option) => option.value === status
  )?.icon;

  return !Item ? (
    <div>
      <h1>No items yet...</h1>
    </div>
  ) : (
    <div className="flex flex-col text-gray-400 font-thin mt-4">
      {/*tags */}
      <div className="flex flex-wrap mt-2 ml-20">
        {Item.tags &&
          Item.tags.map((tag, index) => (
            <Tag
              key={index}
              tag={tag}
              onDelete={handleTagDelete}
              onClick={handleTagClick}
            />
          ))}
      </div>
      {/*complete row : status + text + buttons */}
      <div className="flex flex-row ">
        <div className="mr-0 relative" ref={statusDropdownRef}>
          <button
            onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
            className="rounded-select flex-grow flex-shrink basis-0 p-2 md:ml-2  w-10 h-10 accent-gray-600 opacity-80"
          >
            {selectedStatusIcon}
          </button>
          {isStatusDropdownOpen && (
            <div className="absolute top-8 left-4 bg-white border rounded  z-10">
              {statusOptions.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleStatusChange(option.value)}
                  className="flex items-center p-2 w-full text-left hover:bg-gray-100"
                >
                  {option.icon} <span className="ml-2">{option.label}</span>
                </button>
              ))}
            </div>
          )}
        </div>
        <div className={`box ${isEditing ? "editing-mode" : "view-mode"}`}>
          {isEditing ? (
            <div>
              <textarea
                rows="10"
                cols="400"
                value={Item.description}
                onChange={handleChange}
                className="w-full h-24 text-gray-600 border"
              />
              <button
                onClick={handleSaveClick}
                className="px-4 py-1 m-2 w-10 h-10 text-emerald-400"
              >
                <AiFillCheckCircle />
              </button>
              <button
                onClick={handleCloseClick}
                className="px-4 py-1 m-2 w-10 h-10 text-red opacity-60"
              >
                <AiFillCloseCircle />
              </button>
            </div>
          ) : (
            <div>
              <div
                className={`${
                  checked ? "line-through text-gray-400" : "text-gray-600"
                }  my-2 mx-1 flex-grow flex-shrink-0 basis-0 break-all`}
              >
                {urlify(Item.description)}
              </div>
              <div className="flex-grow flex-shrink basis-0 flex items-center flex-wrap">
                <button
                  onClick={handleEditClick}
                  className="px-4 py-1 m-2 w-10 h-10"
                >
                  <AiOutlineEdit />
                </button>
                <button
                  onClick={handleDeleteClick}
                  className="px-4 py-1 m-2 w-10 h-10"
                >
                  <AiFillDelete />
                </button>
                <button
                  onClick={handleTagPopupOpen}
                  className="px-4 py-1 m-2 w-10 h-10"
                >
                  <AiFillTag />
                </button>
                <button
                  onClick={handleCalendarClick}
                  className="px-4 py-1 m-2 w-10 h-10"
                >
                  <AiFillClockCircle />
                </button>
                {isRangeMode ||
                (targetDateRange.startDate && targetDateRange.endDate)
                  ? targetDateRange.startDate &&
                    targetDateRange.endDate && (
                      <div>
                        <span className="text-gray-400 font-thin md:text-sm text-xs">
                          {`${formatDate(
                            targetDateRange.startDate
                          )} - ${formatDate(
                            targetDateRange.endDate
                          )} (${calculateTotalHours(
                            targetDateRange.startDate,
                            targetDateRange.endDate
                          )} hours)`}
                        </span>
                      </div>
                    )
                  : targetDate && (
                      <div className="w-full sm:w-auto">
                        <span className="text-gray-400 font-thin md:text-sm text-xs">
                          {`${formatDate(targetDate)} (${calculateHoursFromNow(
                            targetDate
                          )} hours)`}
                        </span>
                      </div>
                    )}
                {isCalendarOpen && (
                  <div
                    ref={calendarRef}
                    className="absolute flex flex-col md:left-80 -left-4 z-50 bg-white p-4 shadow-lg w-96"
                  >
                    <div className="flex items-center mb-4">
                      <label className="mr-2">Range Mode</label>
                      <input
                        type="checkbox"
                        checked={isRangeMode}
                        onChange={() => setIsRangeMode(!isRangeMode)}
                      />
                    </div>
                    <DatePicker
                      selected={
                        isRangeMode
                          ? (targetDateRange.startDate &&
                              new Date(targetDateRange.startDate)) ||
                            new Date()
                          : (targetDate && new Date(targetDate)) || new Date()
                      }
                      onChange={(dates) => handleDateChange(dates)}
                      startDate={isRangeMode ? targetDateRange.startDate : null}
                      endDate={isRangeMode ? targetDateRange.endDate : null}
                      selectsRange={isRangeMode}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm"
                      minDate={new Date()}
                      className="w-full"
                      isClearable
                      todayButton="Today"
                    />
                    <button
                      onClick={handleDateConfirm}
                      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <PopupComponent isOpen={isPopupOpen} onClose={cancelDelete}>
        <h2 className="flex items-center justify-center text-xl font-bold">
          Confirm Deletion
        </h2>
        <p>Are you sure you want to delete this item?</p>
        <div className="flex items-center justify-center">
          <button
            onClick={confirmDelete}
            className="bg-red text-white px-4 py-2 rounded m-2"
          >
            Yes, Delete
          </button>
          <button
            onClick={cancelDelete}
            className="bg-gray-500 text-white px-4 py-2 rounded m-2"
          >
            Cancel
          </button>
        </div>
      </PopupComponent>
      <TagPopup
        item={Item}
        isOpen={isTagPopupOpen}
        onClose={handleTagPopupClose}
        onTagAdded={(updatedItem) => setItem(updatedItem)}
      />
    </div>
  );
}

export default CheckListItem;
