import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import adminService from './adminService'
import { extractErrorMessage } from '../../utils'

const initialState = {
  data: null,
  isLoading: false,
  deactivatedUserId: null,
  auditLogs: [], // Added auditLogs to the initial state
}

// Fetch admin data
export const fetchAdminData = createAsyncThunk(
  'admin/fetchAdminData',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await adminService.fetchAdminData(token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

// Deactivate user
export const deactivateUser = createAsyncThunk(
  'admin/deactivateUser',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await adminService.deactivateUser(token,userId)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

// Fetch audit logs
export const fetchAuditLogs = createAsyncThunk(
  'admin/fetchAuditLogs',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await adminService.fetchAuditLogs(token)
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    removeAdminData: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAdminData.fulfilled, (state, action) => {
        state.data = action.payload
        state.isLoading = false
      })
      .addCase(fetchAdminData.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(deactivateUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        const userId = action.payload.userID;
        const user = state.data.users.find(user => user._id === userId);
        if (user) {
          user.isActive = false;
        }
        state.isLoading = false;
      })
      .addCase(deactivateUser.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchAuditLogs.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAuditLogs.fulfilled, (state, action) => {
        state.auditLogs = action.payload
        state.isLoading = false
      })
      .addCase(fetchAuditLogs.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { removeAdminData } = adminSlice.actions;
export default adminSlice.reducer