import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

// Fetch admin data
const fetchAdminData = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL+ '/admin/stats',config)

  // if (response.data) {
  //   localStorage.setItem('adminData', JSON.stringify(response.data))
  // }
  return response.data
}

// Deactivate user
const deactivateUser = async (token,userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL + '/user/deactivate', { userId },config)

  // if (response.data) {
  //   const adminData = JSON.parse(localStorage.getItem('adminData'))
  //   adminData.users = adminData.users.filter(user => user._id !== userId)
  //   localStorage.setItem('adminData', JSON.stringify(adminData))
  // }
  return response.data
}

// Fetch audit logs
const fetchAuditLogs = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + '/admin/audit/logs', config)
  return response.data
}

const adminService = {
  fetchAdminData,
  deactivateUser,
  fetchAuditLogs,
}

export default adminService