import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../features/dashboard/dashboardSlice";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    inProgressItems,
    deadlineItems,
    itemsToStartTomorrow,
    overdueItems,
    totalInProgress,
    totalDeadline,
    totalToStartTomorrow,
    totalOverdue,
    completedItems,
    totalCompleted,
    dueItems,
    activities, // Add activities to the state
  } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);

  const chartData = {
    labels: [
      "In Progress",
      "Deadline",
      "To Start Tomorrow",
      "Overdue",
      "Completed",
    ],
    datasets: [
      {
        label: "Checklist Items Status",
        data: [
          totalInProgress,
          totalDeadline,
          totalToStartTomorrow,
          totalOverdue,
          totalCompleted,
        ],
        backgroundColor: [
          "#cfeb34",
          "#F59E0B",
          "#34baeb",
          "#DC2626",
          "#1b8c44",
        ],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Checklist Items Overview",
      },
    },
  };

  const handleShowAllChecklists = () => {
    navigate("/checklists");
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-600">
      {/* Navbar */}
      <nav className="bg-white shadow">
        <div className="container mx-auto px-4 py-3 flex flex-col sm:flex-row justify-between items-center">
          <h1 className="text-xl font-bold text-gray-800 mb-2 sm:mb-0">
            Checklist Dashboard
          </h1>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 items-center">
            <select className="border border-gray-300 rounded px-3 py-1">
              <option value="all">All Checklists</option>
              <option value="completed">Completed</option>
              <option value="pending">Pending</option>
            </select>
            <input
              type="date"
              className="border border-gray-300 rounded px-3 py-1"
            />
            <button
              className="bg-purple-500 text-white px-4 py-2 rounded"
              onClick={handleShowAllChecklists}
            >
              Show All Checklist
            </button>
          </div>
        </div>
      </nav>

      {/* Dashboard Content */}
      <div className="container mx-auto px-4 py-6">
        {/* Stats Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div className="bg-white p-4 rounded shadow text-center">
            <h2 className="text-lg font-semibold">In Progress Items</h2>
            <p className="text-2xl font-bold">{totalInProgress}</p>
          </div>
          <div className="bg-white p-4 rounded shadow text-center">
            <h2 className="text-lg font-semibold">Deadline Items</h2>
            <p className="text-2xl font-bold ">{totalDeadline}</p>
          </div>
          <div className="bg-white p-4 rounded shadow text-center">
            <h2 className="text-lg font-semibold">Items to Start Tomorrow</h2>
            <p className="text-2xl font-bold ">{totalToStartTomorrow}</p>
          </div>
          <div className="bg-white p-4 rounded shadow text-center">
            <h2 className="text-lg font-semibold">Overdue Items</h2>
            <p className="text-2xl font-bold text-red">{totalOverdue}</p>
          </div>
          <div className="bg-white p-4 rounded shadow text-center">
            <h2 className="text-lg font-semibold">Completed Items</h2>
            <p className="text-2xl font-bold ">{totalCompleted}</p>
          </div>
        </div>

        {/* Main Section */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Chart Section */}
          <div className="col-span-1 lg:col-span-2 bg-white p-6 rounded shadow">
            <h2 className="text-lg font-semibold mb-4">Completion Rate</h2>
            <div className="flex justify-center items-center h-64 bg-gray-50 border border-dashed border-gray-300">
              <Bar data={chartData} options={chartOptions} />
            </div>
          </div>

          {/* Side Panel */}
          <div className="bg-white p-6 rounded shadow">
            <h2 className="text-lg font-semibold mb-4">Upcoming Deadlines</h2>
            <ul className="space-y-2">
              {dueItems.map((item) => (
                <li
                  key={item._id}
                  className="p-3 bg-gray-100 rounded border-l-4 border-purple-500"
                >
                  <p className="font-bold">{item.description}</p>
                  <p className="text-sm text-gray-500">
                    Due: {new Date(item.dueDate).toLocaleString()}
                  </p>
                  <p className="text-sm text-gray-500">
                    Checklist: {item.checklistName}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="mt-6 bg-white p-6 rounded shadow">
          <h2 className="text-lg font-semibold mb-4">Recent Activity</h2>
          <ul className="divide-y divide-gray-200">
            {activities.map((activity, index) => (
              <li
                key={index}
                className="py-3 flex justify-between items-center"
              >
                <span className="w-2/3">{activity.activity}</span>
                <span className="text-sm text-gray-500">
                  {new Date(activity.timestamp).toLocaleString()}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
