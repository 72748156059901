import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createOrUpdateACheckListItem } from "../features/checklistItem/checkListItemSlice";
import { AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";

const TagPopup = ({ item, isOpen, onClose, onTagAdded }) => {
  const [newTag, setNewTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const dispatch = useDispatch();
  const tags = useSelector((state) => {
    const allTags = state.checkListItems.checkListItems.flatMap(
      (checkListItem) => checkListItem.tags
    );
    const uniqueTags = [...new Set(allTags.map((tag) => tag?.name))];
    return uniqueTags
      .map((tagName) => {
        const tag = allTags.find((t) => t.name === tagName);
        return { name: tagName, color: tag.color };
      })
      .filter(
        (tag) =>
          item && item.tags && !item.tags.some((t) => t.name === tag.name)
      );
  });

  const handleTagChange = (event) => {
    setNewTag(event.target.value);
  };

  const handleTagClick = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag.name)
        ? prevSelectedTags.filter((t) => t !== tag.name)
        : [...prevSelectedTags, tag.name]
    );
  };

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleAddTags = () => {
    const updatedTags = [
      ...item.tags.filter((tag) => !selectedTags.includes(tag.name)),
      ...selectedTags.map((tagName) => {
        const existingTag = item.tags.find((t) => t.name === tagName);
        const tagColor =
          tags.find((tag) => tag.name === tagName)?.color || selectedColor;
        return existingTag ? existingTag : { name: tagName, color: tagColor };
      }),
    ];
    if (newTag && !updatedTags.some((tag) => tag.name === newTag)) {
      updatedTags.push({ name: newTag, color: selectedColor });
    }
    dispatch(
      createOrUpdateACheckListItem({
        checkListID: item.checkListID,
        description: item.description,
        status: item.status,
        itemID: item._id,
        tags: updatedTags,
      })
    )
      .unwrap()
      .then((updatedItem) => {
        toast.success("Tags added");
        onTagAdded(updatedItem);
        onClose();
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-40">
      <div className="bg-white p-4 rounded shadow-lg">
        <h2 className="text-xl font-bold mb-4">Add Tags</h2>
        <div className="flex flex-wrap mb-4">
          {tags.map((tag, index) => (
            <span
              key={index}
              className={`px-2 py-1 m-1 rounded cursor-pointer ${
                selectedTags.includes(tag.name)
                  ? "bg-gray-800 text-white"
                  : "text-gray-600"
              }`}
              style={{
                backgroundColor: selectedTags.includes(tag.name)
                  ? "#4a5568"
                  : tag.color || "#e2e8f0",
              }}
              onClick={() => handleTagClick(tag)}
            >
              {tag.name}
            </span>
          ))}
        </div>
        <div className="flex items-center">
          <input
            type="text"
            value={newTag}
            onChange={handleTagChange}
            className="border px-2 py-1 m-1 rounded"
            placeholder="New tag"
          />
          <input
            type="color"
            value={selectedColor}
            onChange={handleColorChange}
            className="border px-2 py-1 m-1 rounded"
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <button
            onClick={handleAddTags}
            className="bg-emerald-400 text-white px-4 py-2 rounded"
          >
            Add Tags
          </button>
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default TagPopup;
