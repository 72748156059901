import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FaHourglassHalf,
  FaCheckCircle,
  FaList,
  FaPlusCircle,
  FaSearch,
} from "react-icons/fa";

import EditableTextBox from "../components/CheckListItem";
import CheckList from "./CheckList";
import Spinner from "../components/Spinner";
import FloatingButton from "../components/FloatingButton";
import Chip from "../components/Chip";
import SearchBar from "../components/SearchBar";
import Tag from "../components/Tag";

import {
  getCheckLists,
  addNewChecklistLocally,
  removeCheckListData,
  getSearchCheckListAndItem,
  updateChecklist,
  clearCheckLists,
} from "../features/checklist/checkListSlice";
import {
  removeCheckListItemData,
  filterItems,
  filterItemsByTags,
  filterItemsByTimeRange,
  getAllTagsForUser,
  getChecklistsByTags,
  clearCheckListItems,
} from "../features/checklistItem/checkListItemSlice";
import { setUser, logout } from "../features/auth/authSlice";

function CheckLists() {
  const { userId } = useParams();
  const { checkLists } = useSelector((state) => state.checkLists);
  const { checkListItems, isLoading, tags } = useSelector(
    (state) => state.checkListItems
  );

  const dispatch = useDispatch();
  const [createNewChecklist, setcreateNewChecklist] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const navigate = useNavigate();
  const typingTimeoutRef = useRef(null);
  const searchBarRef = useRef(null);
  const location = useLocation();

  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setShowSearchBar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onTagClickHandler = (tag) => {
    dispatch(clearCheckLists);
    dispatch(clearCheckListItems);

    dispatch(getChecklistsByTags([tag.name]));
  };

  const onSearchHandler = (search) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setShowSearchBar(false);
    }, 3000);

    dispatch(getSearchCheckListAndItem(search))
      .unwrap()
      .then((user) => {})
      .catch((error) => {
        toast.error(error.message || "Something went wrong!", {
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    if (location.state?.fromDashboard) {
      const { checklistWithItems } = location.state;
      checklistWithItems.forEach(({ checklist, items }) => {
        dispatch(updateChecklist(checklist));
        // Handle the checklistWithItems data here
        // For example, you can set it to the state or dispatch an action to store it
      });
    } else {
      dispatch(getCheckLists(userId))
        .unwrap()
        .then(() => {})
        .catch((err) => {
          if (err.httpStatusCode === 401) {
            dispatch(setUser(null));
            dispatch(removeCheckListData());
            dispatch(removeCheckListItemData());
            dispatch(logout());
            navigate("/login");
          } else {
            toast.error(err);
          }
        });
      console.log("useeffect of getChecklist ws called");
    }
    dispatch(getAllTagsForUser());
  }, [dispatch, userId, navigate, location.state]);

  const handleCheckListAdd = () => {
    dispatch(addNewChecklistLocally({ name: "", status: "pending" }));
  };

  const handleFilterForLocalItems = (tag) => {
    dispatch(filterItems({ status: tag.name }));
  };

  const handleAddCheckListBox = () => {
    setcreateNewChecklist(true);
  };

  const handleTagClick = (tag) => {
    const updatedTags = selectedTags.map((t) => t.name).includes(tag.name)
      ? selectedTags.filter((t) => t.name !== tag.name)
      : [...selectedTags, tag];
    setSelectedTags(updatedTags);
    // console.log("selectedTags", updatedTags);
    if (updatedTags.length === 0) {
      dispatch(getCheckLists(userId));
    } else {
      dispatch(filterItemsByTags(updatedTags));
      dispatch(getChecklistsByTags(updatedTags.map((tag) => tag.name)))
        .unwrap()
        .then((checklistWithItems) => {
          dispatch(clearCheckLists());
          checklistWithItems.forEach(({ checklist, items }) => {
            dispatch(updateChecklist(checklist));
          });
        })
        .catch((err) => {
          if (err.httpStatusCode === 401) {
            dispatch(setUser(null));
            dispatch(removeCheckListData());
            dispatch(removeCheckListItemData());
            dispatch(logout());
            navigate("/login");
          } else if (err.httpStatusCode === 400) {
            dispatch(getCheckLists(userId));
          } else {
            toast.error(err);
          }
        });
    }
  };

  const handlePendingItemsClick = () => {
    const now = new Date();
    const within12Hours = checkListItems.filter((item) => {
      const endDate = new Date(item.endDate);
      return (
        item.status === "pending" &&
        item.startDate &&
        item.endDate &&
        endDate - now <= 12 * 60 * 60 * 1000
      );
    });
    dispatch(filterItemsByTimeRange(within12Hours));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div id="checklist-page">
      <>
        {checkLists.length === 0 ? (
          <h3>No Checklists created yet...</h3>
        ) : (
          <>
            <div className="sticky top-0 flex flex-wrap gap-2 items-center justify-center  py-2 px-2 bg-white z-20">
              {!showSearchBar && (
                <FaSearch
                  className="cursor-pointer text-gray-600"
                  onClick={() => setShowSearchBar(true)}
                />
              )}
              <div
                ref={searchBarRef}
                className={`origin-right transform transition-transform duration-200 ease-out ${
                  showSearchBar ? "scale-x-100" : "scale-x-0"
                }`}
              >
                {showSearchBar && <SearchBar onClick={onSearchHandler} />}
              </div>
              {/* <Tag
                tag={{ name: "pending", color: "gray" }}
                onClick={handleFilterForLocalItems}
                icon={<FaHourglassHalf />}
              />
              <Tag
                tag={{ name: "completed", color: "gray" }}
                onClick={handleFilterForLocalItems}
                icon={<FaCheckCircle />}
              />
              <Tag
                tag={{ name: "Reset", color: "orange" }}
                onClick={handleFilterForLocalItems}
                icon={<FaList />}
              />
              <Tag
                tag={{ name: "Add Checklist", color: "gray" }}
                onClick={handleCheckListAdd}
                icon={<FaPlusCircle />}
              /> */}
              {/* <Tag
                tag={{ name: "Pending Items (12h)", color: "red" }}
                onClick={handlePendingItemsClick}
                icon={<FaHourglassHalf />}
              /> */}
              {tags.map((tag) => (
                //  console.log("tag", selectedTags),
                <Tag
                  key={tag}
                  tag={{ name: tag, color: "gray" }}
                  onClick={handleTagClick}
                  isSelected={selectedTags
                    .map((item) => item.name)
                    .includes(tag)}
                />
              ))}
            </div>

            <div>
              {checkLists.map((checkList) => (
                <CheckList
                  key={checkList._id}
                  checklist={checkList}
                  enableAddButton={true}
                />
              ))}
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default CheckLists;
