import { FaSearch } from "react-icons/fa";
import { useState } from "react";

const SearchBar = ({ onClick }) => {
  const [search, setSearch] = useState("");
  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onClickHandler = () => {
    onClick(search);
  };

  return (
    <div className="flex justify-center">
      <div className="flex px-2 py-1 mx-2 my-4 border rounded text-gray-600">
        <input
          type="text"
          placeholder="Search..."
          id="search"
          name="search"
          value={search}
          onChange={onChange}
        />
        <button
          className="px-1 py-2 mx-2 text-gray-600  rounded"
          onClick={onClickHandler}
        >
          <FaSearch />
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
