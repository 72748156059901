import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const Tag = ({ tag, onDelete, onClick, isSelected }) => {
  return (
    <span
      className="px-2 py-0 m-1 font-thin text-xs rounded-full flex items-center cursor-pointer hover:opacity-40"
      style={{
        backgroundColor:
          (tag.color === "#ffffff" ? "#e2e8f0" : tag.color) || "#e2e8f0",
        color: "#ffffff",
        border: isSelected ? "2px solid #000000" : "none",
      }}
      onClick={() => onClick(tag)}
    >
      {tag.name}
      {onDelete && (
        <AiFillCloseCircle
          className="ml-2 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(tag);
          }}
        />
      )}
    </span>
  );
};

export default Tag;
