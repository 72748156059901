import React from "react";

const TagBar = () => {
  const tags = [
    "Work",
    "Personal",
    "Urgent",
    "Completed",
    "Pending",
    "Overdue",
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-4 flex flex-wrap items-center justify-between gap-4">
      {/* Search Input */}
      <div className="flex items-center w-full md:w-auto">
        <input
          type="text"
          placeholder="Search..."
          className="border border-gray-300 rounded-lg px-4 py-2 w-full md:w-64 focus:ring focus:ring-blue-300 focus:outline-none"
        />
        <button className="ml-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
          Search
        </button>
      </div>

      {/* Tags Section */}
      <div className="flex flex-wrap items-center gap-2">
        {tags.map((tag, index) => (
          <span
            key={index}
            className="bg-gray-100 text-gray-600 text-sm px-3 py-1 rounded-full hover:bg-gray-200 cursor-pointer"
          >
            {tag}
          </span>
        ))}
      </div>

      {/* Add Checklist Button */}
      <button className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700">
        + Add Checklist
      </button>
    </div>
  );
};

export default TagBar;
