// NOTE: here should be loadingSpinner on className of inner div
function Spinner() {
  return (
    <div class="flex items-center justify-center min-h-screen">
      <h1 class="text-4xl font-bold text-gray-600 animate-pulse">
        List-It-All
      </h1>
    </div>
  );
}

export default Spinner;
